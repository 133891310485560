
.blocker {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width:100%;  
  z-index: 50;       
  background: rgba(72, 71, 71, 0.5) repeat;  
  }

.popup{
    z-index: 100;   
    margin-top: 400px;      
    display: flex;
    align-items: center;
    justify-content: center;
    }

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 25s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(20deg);
  }
  to {
    transform: rotate(360deg);
  }
}
