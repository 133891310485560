@media (min-width: 1280px) {
  .home .MuiContainer-maxWidthLg {
    max-width: none !important;
  }
}

.home{
  background-color: #F4F4F4;
  display: flex;
  height: fit-content;
  padding-top: 65px;
}
